import React, { useState, useEffect } from 'react';
import './App.css';

function App() {
  const [cards, setCards] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [flipped, setFlipped] = useState(false);
  const [cycleCount, setCycleCount] = useState(0); // Tracks the number of complete cycles through the cards

  useEffect(() => {
    fetch('/data.json')
      .then(res => res.json())
      .then(data => setCards(data))
      .catch(err => console.error("Failed to load data", err));
  }, []);

  const handleFlip = () => {
    setFlipped(!flipped);
  };

  const handleNext = () => {
    setFlipped(false);
    const nextIndex = (currentIndex + 1) % cards.length;
    setCurrentIndex(nextIndex);
    // Check if we've returned to the start of the array
    if (nextIndex === 0) {
      setCycleCount(cycleCount + 1);
    }
  };

  // Determine button color based on the cycle count
  const buttonColor = cycleCount % 3 === 0 ? "btn-primary" : 
                      cycleCount % 3 === 1 ? "btn-success" : "btn-warning";

  return (
    <div className="container mt-5">
      <div className="card text-center" style={{ width: '18rem', cursor: 'pointer' }}>
        <div className="card-body" onClick={handleFlip}>
          {flipped ? (
            <>
              <h3 className="card-title">{cards[currentIndex]?.word}</h3>
              <p className="card-text">({cards[currentIndex]?.meaning})</p>
              <p className="card-text">{cards[currentIndex]?.sentence}</p>
            </>
          ) : (
            <h5 className="card-title">{cards[currentIndex]?.word}</h5>
          )}
        </div>
        <button className={`btn ${buttonColor}`} onClick={handleNext}>Next Word</button>
      </div>
    </div>
  );
}

export default App;
